<template>
  <svg width="20" height="24" viewBox="0 0 20 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask
      id="mask0_961_67966"
      style="mask-type: alpha"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="20"
      height="24"
    >
      <path
        d="M18.3824 24H1.61765C0.724246 24 0 23.2325 0 22.2857V1.71429C0 0.767512 0.724246 0 1.61765 0H12.5L20 7.94805V22.2857C20 23.2325 19.2757 24 18.3824 24Z"
        fill="url(#paint0_linear_961_67966)"
      />
    </mask>
    <g mask="url(#mask0_961_67966)">
      <path
        d="M18.3824 24H1.61765C0.724246 24 0 23.2325 0 22.2857V1.71429C0 0.767512 0.724246 0 1.61765 0H12.5L20 7.94805V22.2857C20 23.2325 19.2757 24 18.3824 24Z"
        fill="#C3C7D2"
      />
      <path
        d="M18.3824 24H1.61765C0.724246 24 0 23.2325 0 22.2857V1.71429C0 0.767512 0.724246 0 1.61765 0H12.5L20 7.94805V22.2857C20 23.2325 19.2757 24 18.3824 24Z"
        fill="url(#paint1_linear_961_67966)"
        fill-opacity="0.2"
      />
      <g opacity="0.27" filter="url(#filter0_f_961_67966)">
        <g style="mix-blend-mode: darken" opacity="0.27">
          <path d="M16.6178 11.6104H3.52954V18.1558H16.6178V11.6104Z" fill="#0072A2" />
        </g>
      </g>
      <g filter="url(#filter1_d_961_67966)">
        <path
          d="M20 7.94805H14.1176C13.2243 7.94805 12.5 7.18054 12.5 6.23377V0L20 7.94805Z"
          fill="url(#paint2_linear_961_67966)"
        />
      </g>
      <path
        d="M7.41895 16H6.54004L4.8291 13.1934V16H3.9502V11.7344H4.8291L6.54297 14.5469V11.7344H7.41895V16ZM10.7529 14.1514H9.06543V15.2939H11.0459V16H8.18652V11.7344H11.04V12.4463H9.06543V13.4629H10.7529V14.1514ZM14.8838 14.7695L15.458 11.7344H16.334L15.3877 16H14.5029L13.8086 13.1465L13.1143 16H12.2295L11.2832 11.7344H12.1592L12.7363 14.7637L13.4395 11.7344H14.1836L14.8838 14.7695Z"
        fill="white"
      />
    </g>
    <defs>
      <filter
        id="filter0_f_961_67966"
        x="-35.4705"
        y="-27.3896"
        width="91.0881"
        height="84.5454"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="19.5" result="effect1_foregroundBlur_961_67966" />
      </filter>
      <filter
        id="filter1_d_961_67966"
        x="-10.5"
        y="-24"
        width="57.5"
        height="57.948"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="2" dy="1" />
        <feGaussianBlur stdDeviation="12.5" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.33 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_961_67966" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_961_67966"
          result="shape"
        />
      </filter>
      <linearGradient
        id="paint0_linear_961_67966"
        x1="10"
        y1="0"
        x2="10"
        y2="24"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#FF7979" />
        <stop offset="1" stop-color="#E85555" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_961_67966"
        x1="20"
        y1="12"
        x2="6.95314e-08"
        y2="12"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="white" />
        <stop offset="1" stop-color="white" stop-opacity="0" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_961_67966"
        x1="16.25"
        y1="0"
        x2="16.25"
        y2="7.94805"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#CDCFD2" />
        <stop offset="1" stop-color="#808592" />
      </linearGradient>
    </defs>
  </svg>
</template>

<script>
export default {
  name: "AI",
  mixins: [],
  props: {},
  components: {},
  data() {
    return {};
  },
  mounted() {},
  methods: {},
  computed: {},
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors";
</style>
